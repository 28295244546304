<template>
  <g v-if="refname" :id="refname" :transform="`translate(${margins.left}, 20)`">
    <g
      class="dynamic-legend cursor-pointer"
      v-for="(legend, key) in legends"
      :key="key"
      @click="toggleDisplay(legend)"
      :style="{ opacity: inactive.indexOf(legend.id) !== -1 ? 0.5 : 1 }"
    >
      <rect rx="3" ry="3" :y="-11" :x="4" width="12" height="12" :fill="`${legend.color}`"></rect>
      <text :class="`linelegend-${legend.id}`">
        {{ `\u00A0\u00A0\u00A0 ${legend.label}` }}
      </text>
    </g>
    <g v-if="showhideall" :transform="`translate(-28, -16)`" class="text-green-600">
      <a xlink:href="#" @click="toggleDisplayAll()" v-tooltip="getTooltipMessage()" class="focus:outline-none">
        <circle :transform="`translate(8, 12)`" cx="0" cy="0" r="12" fill="transparent"></circle>
        <svg v-show="!displayAll" class="w-6 h-6" fill="none" stroke="currentColor">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
          ></path>
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
          ></path>
        </svg>
        <svg v-show="displayAll" class="w-6 h-6" fill="none" stroke="currentColor">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
          ></path>
        </svg>
      </a>
      <!-- <text @click="toggleDisplayAll()">Göm/visa alla</text> -->
    </g>
  </g>
</template>

<script>
/**
 * legends prop is an array of objects that look like the following:
 * {
 *   label: "",
 *   color: "",
 *   id: ""
 * }
 */
import d3 from "@/components/graph/d3modules";

export default {
  name: "ChartLegend",
  props: {
    margins: Object,
    legends: Array,
    showhideall: {
      type: Boolean,
      default: () => true,
    },
    refname: String,
  },
  data: () => ({
    textw: 0,
    inactive: [],
    displayAll: true,
  }),
  watch: {
    inactive: "inactivate",
  },
  beforeUpdate() {
    this.$nextTick(() => {
      this.transformText();
    });
  },
  mounted() {
    this.$nextTick(() => {
      this.transformText();
    });
  },
  methods: {
    transformText() {
      if (!this.refname) return;
      this.textw = 0;

      d3.select(`#${this.refname}`)
        .selectAll("g.dynamic-legend")
        .attr("transform", (d, i) => {
          if (i === 0) {
            return `translate(0, 0)`;
          }
          const prev = this.legends[i - 1];
          const x = d3.select(`.linelegend-${prev.id}`).node().getBBox();

          this.textw += x.width + 8;
          return `translate(${this.textw}, 0)`;
        });
    },
    toggleDisplay(d) {
      const index = this.inactive.indexOf(d.id);
      if (this.inactive.indexOf(d.id) === -1) {
        this.inactive.push(d.id);
      } else {
        this.inactive.splice(index, 1);
      }
    },
    toggleDisplayAll() {
      this.displayAll = !this.displayAll;
      if (this.inactive.length === 0) {
        this.inactive = this.legends.map(d => d.id);
      } else {
        this.inactive.splice(0, this.legends.length);
      }
    },
    inactivate() {
      this.$emit("inactivate", this.inactive);
    },
    getTooltipMessage() {
      return `${this.$t("tooltip.showhide")}`;
    },
  },
};
</script>

<style>
</style>