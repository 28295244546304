<template>
  <svg ref="graph" v-if="w > 0" :viewBox="viewBox" :width="width" :height="height">
    <clipPath id="clip">
      <rect
        :width="width - margins.left - margins.right"
        :height="innerHeight"
        :transform="`translate(${margins.left}, ${margins.top})`"
      ></rect>
    </clipPath>

    <g id="measurementcontainer" :transform="gtransform">
      <chart-legend :legends="legends()" :margins="margins" @inactivate="inactivate" :refname="`legend-${graphname}`" />

      <chart-axis
        :scale="xscale"
        :width="width"
        :height="height"
        :margins="margins"
        :zoomtransform="zoomtransform"
        placement="top"
      />
      <chart-axis
        v-if="showLeftY"
        :scale="yscaleLeft"
        :width="width"
        :height="height"
        :margins="margins"
        :zoomtransform="zoomtransform"
        placement="left"
      />
      <chart-axis
        :scale="xscale"
        :width="width"
        :height="height"
        :margins="margins"
        :zoomtransform="zoomtransform"
        :guide="true"
        placement="bottom"
      />
      <chart-axis
        v-if="showRightY"
        :scale="yscaleRight"
        :width="width"
        :height="height"
        :margins="margins"
        :zoomtransform="zoomtransform"
        placement="right"
      />

      <g v-for="(variables, modulename) in linedata" :key="modulename">
        <g v-for="(data, varname) in variables" :key="varname" v-show="activelines[modulename][varname]">
          <chart-line
            :xscale="xscale"
            :yscale="getYScaleFormVarname(modulename, varname)"
            :zoomtransform="zoomtransform"
            :line="data"
            :meta="metadata(modulename, varname)"
            :name="varname"
            :hovered="varname === hovered"
          />
          <chart-dot
            :xscale="xscale"
            :height="innerHeight"
            :yscale="getYScaleFormVarname(modulename, varname)"
            :zoomtransform="zoomtransform"
            :line="data"
            :meta="metadata(modulename, varname)"
            :name="varname"
            @chart-dot-hover="dotHovered"
            @chart-dot-unhover="dotUnhovered"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
import d3 from "@/components/graph/d3modules";

import ChartLine from "./ChartLine";
import ChartDot from "./ChartDot";
import ChartAxis from "./ChartAxis";
import ChartLegend from "./ChartLegend";

export default {
  name: "MeasurementsGraph",
  props: [
    "graphname",
    "height",
    "width",
    "xscale",
    "linedata",
    "linepoints",
    "mindate",
    "maxdate",
    "linemeta",
    "zoom",
    "zoomtransform",
  ],
  components: {
    ChartLine,
    ChartDot,
    ChartAxis,
    ChartLegend,
  },
  mounted() {
    this.$nextTick(() => {
      this.call();
    });
  },
  data: () => ({
    margins: {
      top: 60,
      right: 50,
      bottom: 20,
      left: 40,
    },
    inactive: [],
    hovered: null,
  }),
  watch: {
    zoomtransform: {
      immediate: false,
      handler: function () {
        this.updateZoom();
      },
    },
  },
  computed: {
    showLeftY() {
      return this.linemeta?.left ? true : false;
    },
    showRightY() {
      return this.linemeta?.right ? true : false;
    },
    yLeftSettings() {
      return this.linemeta?.left;
    },
    yRightSettings() {
      return this.linemeta?.right;
    },
    linepointsWithMeta() {
      return this.linepoints.map(d => {
        return {
          metadata: this.metadata(d.modulename, d.varname),
          ...d,
        };
      });
    },
    linevaluesLeft() {
      if (!this.linedata) return [];

      return this.linepointsWithMeta.filter(d => d.metadata?.axis === "left").map(d => +d.value);
    },
    linevaluesRight() {
      if (!this.linedata) return [];
      return this.linepointsWithMeta.filter(d => d.metadata?.axis === "right").map(d => +d.value);
    },
    valueRangeLeft() {
      let minValue = 0; //standard scale 0-100
      let maxValue = 100; //standard scale 0-100

      if (this.linemeta?.left?.display && this.linemeta?.left?.display == "strict") {
        maxValue = this.linemeta?.left?.max || maxValue;
        minValue = this.linemeta?.left?.min || minValue;
      } else if (this.linemeta?.left?.display && this.linemeta?.left?.display == "loose") {
        maxValue = d3.max([d3.max(this.linevaluesLeft), this.linemeta?.left?.max, maxValue]);
        minValue = d3.min([d3.min(this.linevaluesLeft), this.linemeta?.left?.min, minValue]);
      } else {
        maxValue = d3.max(this.linevaluesLeft);
        minValue = d3.min(this.linevaluesLeft);
      }

      return [minValue, maxValue * 1.05];
    },
    valueRangeRight() {
      let minValue = 0;
      let maxValue = 100;

      if (this.linemeta?.right?.display && this.linemeta?.right?.display == "strict") {
        maxValue = this.linemeta?.right?.max || maxValue;
        minValue = this.linemeta?.right?.min || minValue;
      } else if (this.linemeta?.right?.display && this.linemeta?.right?.display == "loose") {
        maxValue = d3.max([d3.max(this.linevaluesRight), this.linemeta?.right?.max, maxValue]);
        minValue = d3.min([d3.min(this.linevaluesRight), this.linemeta?.right?.min, minValue]);
      } else {
        maxValue = d3.max(this.linevaluesRight);
        minValue = d3.min(this.linevaluesRight);
      }

      return [minValue, maxValue * 1.05];
    },
    yscaleLeft() {
      return d3
        .scaleLinear()
        .domain(this.valueRangeLeft)
        .range([this.height - this.margins.bottom, this.margins.top]);
    },
    yscaleRight() {
      return d3
        .scaleLinear()
        .domain(this.valueRangeRight)
        .range([this.height - this.margins.bottom, this.margins.top]);
    },
    innerHeight() {
      const h = this.height - this.margins.top - this.margins.bottom;
      return h > 0 ? h : 1;
    },
    gtransform() {
      return `translate(0, 0)`;
    },
    h() {
      return this.height;
    },
    w() {
      return this.width;
    },
    viewBox() {
      return `0,0,${this.w || 0},${this.h || 0}`;
    },
    activelines() {
      if (!this.linedata) return;

      let lines = this.linedata;
      lines = Object.entries(lines).reduce((accm, curm) => {
        let tmp = Object.entries(curm[1]).filter(d => {
          return this.inactive.indexOf(d[0]) === -1 ? d[1] : null;
        });
        accm[curm[0]] = Object.fromEntries(tmp);
        return accm;
      }, {});

      return lines;
    },
    // yScale(){
    //   return d3
    //     .scaleLinear()
    //     .domain(this.valueRange)
    //     .range([this.height - this.margins.bottom, this.margins.top]);
    // },
    // valueRange() {
    //   return [0, d3.max(this.linevaluesLeft) * 1.05];
    // },
    // linevalues() {
    //   if (!this.linedata) return [];
    //   return this.linepoints.map(d => +d.value);
    // },
    // valueRange() {
    //   return [0, d3.max(this.linevalues) * 1.05];
    // },
  },
  methods: {
    call() {
      this.zoom.on("zoom", this.zoomed);
      this.updateZoom();
    },
    updateZoom() {
      d3.select(this.$refs.graph).call(this.zoom);
    },
    zoomed({ transform }) {
      this.$emit("zoomed", transform);
    },
    legends() {
      if (!this.linemeta || !this.linemeta.modules) return;

      let legends = [];
      const meta = this.linemeta.modules;

      Object.entries(meta).map(e => {
        Object.entries(e[1]).map(d => {
          legends.push({
            label: d[1]?.label || d[0],
            color: d[1]?.line?.color || "black",
            id: d[0],
          });
        });
      });

      return legends;
    },
    metadata(modulename, varname) {
      if (!this.linemeta.modules[modulename] || !this.linemeta.modules[modulename][varname]) return {};

      return this.linemeta.modules[modulename][varname];
    },
    getYScaleFormVarname(modulename, varname) {
      const metadata = this.metadata(modulename, varname);

      return metadata?.axis === "right" ? this.yscaleRight : this.yscaleLeft;
    },
    inactivate(d) {
      this.inactive = d;
    },
    dotHovered({ name }) {
      this.hovered = name;
    },
    dotUnhovered() {
      this.hovered = false;
    },
  },
};
</script>

<style>
</style>