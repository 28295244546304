<template>
  <div class="w-full bg-gray-100 shadow hidden sm:block px-2 py-1">
    <nav class="flex space-x-2" aria-label="Tabs">
      <a
        href="#"
        v-for="(graph, key) in graphs"
        :key="key"
        @click="selectGraph(graph)"
        :class="{
          'text-gray-500 hover:text-gray-700 px-2 py-1 font-medium text-sm rounded-md': selected !== graph.name,
          'bg-indigo-100 text-indigo-700 px-2 py-1 font-medium text-sm rounded-md': selected === graph.name,
        }"
      >
        {{ graph.name }}
      </a>
    </nav>
  </div>
  <!-- <div class="bg-gray-100 text-gray-800 flex text-sm items-center">
    <div
      class="px-2 py-1 hover:bg-gray-600 hover:text-white cursor-pointer transition-all duration-100"
      v-for="(graph, key) in graphs"
      :key="key"
      @click="selectGraph(graph)"
      :class="{ 'bg-gray-800 text-white': selected === graph.name }"
    >
      {{ graph.name }}
    </div>
  </div> -->
</template>

<script>
export default {
  name: "GraphList",
  props: ["graphs", "selected"],
  methods: {
    selectGraph(graph) {
      this.$emit("selectGraph", graph);
    },
  },
};
</script>
