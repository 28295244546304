<template>
  <div ref="graphcontainer">
    <div ref="grapheader" class="flex justify-between items-center">
      <graph-list ref="graphlist" :graphs="graphlist" :selected="selectedGraph" @selectGraph="loadGraph($event.name)" />
    </div>
    <div class="relative">
      <graph-controls
        v-if="loaded"
        :width="width"
        :xscale="xscale"
        @zoomin="zoomin"
        @zoomreset="zoomreset"
        :zoom="zoom"
        :zoomtransform="zoomtransform"
        ref="graphcontrols"
      />
    </div>

    <div v-if="loaded" class="flex-col flex-1">
      <measurements-graph
        :graphname="selectedGraph"
        :height="graphHeights.measurements"
        :width="width"
        :xscale="xscale"
        :linedata="linedata"
        :linepoints="linepoints"
        :mindate="mindate"
        :maxdate="maxdate"
        :linemeta="linemeta"
        :zoom="zoom"
        :zoomtransform="zoomtransform"
        @zoomed="updateZoom"
      />
      <event-graph
        :height="graphHeights.event"
        :width="width"
        :xscale="xscale"
        :events="events"
        :zoom="zoom"
        :zoomtransform="zoomtransform"
        @zoomed="updateZoom"
      />
      <therapy-graph
        :height="graphHeights.therapy"
        :width="width"
        :xscale="xscale"
        :therapies="therapydata"
        :zoom="zoom"
        :zoomtransform="zoomtransform"
        @zoomed="updateZoom"
      />
    </div>
  </div>
</template>

<script>
import d3 from "@/components/graph/d3modules";
import { sub } from "date-fns";
import { mapState } from "vuex";

import GraphControls from "./GraphControls";
import GraphList from "./GraphList";
import MeasurementsGraph from "./MeasurementsGraph";
import TherapyGraph from "./TherapyGraph";
import EventGraph from "./EventGraph";

export default {
  name: "GraphContainer",
  props: ["h", "w"],
  components: {
    GraphControls,
    GraphList,
    MeasurementsGraph,
    TherapyGraph,
    EventGraph,
  },
  data() {
    return {
      maxDateToday: true,
      selectedGraph: null,
      loaded: false,
      linedata: null,
      linemeta: null,
      graphname: null,
      therapies: null,
      events: null,
      height: this.h || null,
      width: this.w || null,
      zoom: null,
      zoomtransform: null,
      margins: {
        top: 0,
        right: 50,
        bottom: 0,
        left: 40,
      },
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.zoom = d3.zoom().scaleExtent([1, 100]);

    if (this.$route.query.graph) {
      let graphname = this.$route.query.graph;
      this.loadGraph(graphname);
    } else if (this.graphlist !== null) {
      this.loadGraph(this.graphlist[0].name);
    }
  },
  computed: {
    ...mapState("patient", {
      graphlist: state => state.graphs,
    }),
    graphHeights() {
      const eventHeight = 50;
      const therapyHeight = 100;
      const measurementHeight = this.height - eventHeight - therapyHeight;

      return {
        measurements: measurementHeight,
        event: eventHeight,
        therapy: therapyHeight,
      };
    },
    linepoints() {
      if (!this.linedata) return [];

      let data = [];

      Object.entries(this.linedata).map(e => {
        Object.entries(e[1]).map(d => {
          d[1].map(cur => {
            cur.modulename = e[0];
            cur.varname = d[0];
            cur.value = +cur.value;
            cur.dateobj = new Date(cur.date);
            data.push(cur);
          });
          d[1].sort((a, b) => {
            return a.dateobj - b.dateobj;
          });
        });
      });

      return data;
    },
    lineDateRange() {
      if (!this.linedata) return [];

      return this.linepoints.map(d => d.dateobj);
    },
    mindate() {
      const dates = [this.lineDateRange, this.eventDateRange, this.therapyDateRange].filter(d => {
        const inner = d.filter(v => v);
        return inner.length > 0;
      });

      const min = sub(d3.min(dates.flat()), {
        days: 10,
      });

      return min;
    },
    maxdate() {
      return d3.max([this.lineDateRange, this.eventDateRange, this.therapyDateRange].flat());
    },
    therapydata() {
      return this.therapies.map(d => {
        d.start_date = new Date(d.start_date);
        d.end_date = d.end_date ? new Date(d.end_date) : new Date();
        return d;
      });
    },
    therapyDateRange() {
      return [
        d3.min(this.therapies.map(d => new Date(d.start_date))),
        d3.max(this.therapies.map(d => new Date(d.end_date))),
      ];
    },
    eventDateRange() {
      const tmp = Object.values(this.events)
        .map(d => d.map(d => new Date(d.date)))
        .flat();

      return [d3.min(tmp), d3.max(tmp)];
    },
    daterange() {
      const maxDateToday = this.maxDateToday ? new Date() : null;

      return d3.extent([this.mindate, this.maxdate, maxDateToday]);
    },
    /**
     * xscale  needs to be on the container level, since all graphs depend on it
     * margins.left and right then also needs to be on the container level because otherwise, related graphs will be out of position on the x-axis
     */
    xscale() {
      return d3
        .scaleTime()
        .domain(this.daterange)
        .nice(100, 1)
        .range([this.margins.left, this.width - this.margins.right]);
    },
  },
  methods: {
    handleResize() {
      if (!this.$refs.graphcontainer) return;

      const headerHeight = this.$refs?.grapheader?.$el?.clientHeight || 0;
      const outer = this.$refs.graphcontainer.getBoundingClientRect();

      const calcHeight = outer.height - outer.top - headerHeight;

      this.height = calcHeight > 0 ? calcHeight : 1;

      this.width = outer.width;
    },
    loadGraph(graphname) {
      if (!this.graphlist && !graphname) return;

      this.handleResize();
      this.selectedGraph = graphname;

      this.$store
        .dispatch("patient/getGraph", graphname)
        .then(d => {
          this.linemeta = d.lines ? d.lines.meta : null;
          this.linedata = d.lines ? d.lines.measurements : null;
          this.therapies = d.therapies;
          this.events = d.events;

          this.handleResize();

          this.loaded = true;
        })
        .catch(error => {
          console.log(error);
        });
    },
    updateZoom(transform) {
      this.zoom.translateExtent([
        [0, 0],
        [this.width, 0],
      ]);
      this.zoomtransform = transform;
    },

    zoomin(evt, { months }) {
      const zoomlevel = sub(this.maxdate, { months: months });
      const w = this.width - this.margins.left - this.margins.right;
      const fixedScale = w / (this.xscale(this.maxdate) - this.xscale(zoomlevel));

      const newTransform = d3.zoomIdentity.scale(fixedScale).translate(-w + (w - this.xscale(zoomlevel)), 0);

      this.updateZoom(newTransform);
    },
    zoomreset() {
      this.updateZoom(d3.zoomIdentity.scale(1));
    },
  },
};
</script>
