<template>
  <g>
    <g v-for="(event, key) in group[1]" :key="`marker_${key}`">
      <path
        v-tooltip="getTooltipMessage"
        class="marker"
        d="M0,0l-8.8-17.7C-12.1-24.3-7.4-32,0-32h0c7.4,0,12.1,7.7,8.8,14.3L0,0z"
        :transform="`translate(${x}, ${y})`"
      />
    </g>
    <g v-for="(event, key) in group[1]" :key="`text_${key}`">
      <text
        class="markertext"
        :x="x"
        :y="y - 10"
        :dy="eventY(key)"
        text-anchor="middle"
        fill="white"
        pointer-events="none"
        >{{ eventInitial(event.type) }}</text
      >
    </g>
  </g>
</template>

<script>
// import d3 from "@/components/graph/d3modules";
export default {
  name: "ChartEvent",
  props: ["group", "x", "y", "eventtype", "event"],
  data: () => ({
    xn: null,
    attrs: {},
  }),
  watch: {
    xscale: "zoomed",
    zoomtransform: "zoomed",
  },
  mounted() {
    // this.$nextTick(() => {
    //   this.call();
    // });
  },
  computed: {
    getTooltipMessage() {
      let msg = this.group[1].map(d => {
        return `<strong>${d.type}</strong><br>${d.date}`;
      });

      return msg.join("<br>");
    },
  },
  methods: {
    eventY(key) {
      return -(key * 10);
    },
    eventInitial(text) {
      return text.substr(0, 1).toUpperCase();
    },
    // call() {
    //   d3.selectAll(this.$refs.marker).on("mouseover", () => console.log("clicked", this.group));
    // },
  },
};
</script>

<style>
.marker {
  fill: #31558d;
  fill-opacity: 0.75;
}
.marker:hover {
  fill: #8d3155;
  fill-opacity: 1;
  cursor: pointer;
}
.markertext {
  font-size: 0.7em;
}
</style>