<template>
  <path
    ref="path"
    clip-path="url(#clip)"
    :d="linePath"
    :stroke-width="strokeWidth"
    :stroke="strokeColor"
    fill="none"
  ></path>
</template>

<script>
import d3 from "@/components/graph/d3modules";

export default {
  name: "ChartLine",
  props: ["xscale", "yscale", "line", "zoomtransform", "meta", "name", "hovered"],
  data: () => ({
    zoomgenerator: null,
    xn: null,
    curves: {
      curveLinear: d3.curveLinear,
      curveMonotoneX: d3.curveMonotoneX,
      curveMonotoneY: d3.curveMonotoneY,
    },
  }),
  watch: {
    xscale: "zoomed",
    zoomtransform: "zoomed",
  },
  computed: {
    lineGenerator() {
      return d3
        .line()
        .curve(this.curve)
        .x(d => this.xscale(d.dateobj))
        .y(d => this.yscale(d.value));
    },
    linePath() {
      if (this.zoomgenerator !== null) {
        return this.zoomedLine;
      }

      return this.lineGenerator(this.line);
    },
    curve() {
      const c = this.meta?.curve || "curveLinear";
      return this.curves[c];
    },
    zoomedLine() {
      if (!this.zoomgenerator) return false;

      return this.zoomgenerator(this.line);
    },
    strokeColor() {
      return this.meta?.line?.color || "black";
    },
    strokeWidth() {
      let w = this.meta?.line?.width || 1;

      return this.hovered ? +w + 1 : w;
    },
  },
  methods: {
    zoomed() {
      if (!this.zoomtransform) return;

      this.xn = this.zoomtransform.rescaleX(this.xscale.copy());

      this.zoomgenerator = d3
        .line()
        .curve(this.curve)
        .x(d => {
          return this.xn(d.dateobj);
        })
        .y(d => {
          return this.yscale(d.value);
        });
    },
  },
};

/**
  container - get data, setup and keep track of axis definitions
    chart
      - axes?
      - line
      - bar
      - ...
    chart
      - line


*/
</script>

<style>
</style>
