<template>
  <g clip-path="url(#clip)">
    <circle
      v-tooltip="getTooltipMessage(dot)"
      class="dot"
      ref="dot"
      :cx="cx(dot)"
      :cy="cy(dot)"
      :r="r(dot)"
      :fill="getFill(dot)"
      :stroke="getStroke(dot)"
      v-for="dot in line"
      :id="`${dot.id}-${name}`"
      :key="dot.id"
    ></circle>
  </g>
</template>

<script>
import d3 from "@/components/graph/d3modules";
import { inRange } from "lodash";

export default {
  name: "ChartDot",
  props: ["xscale", "yscale", "line", "zoomtransform", "meta", "name", "height"],
  data: () => ({
    xn: null,
    attrs: {},
  }),
  watch: {
    xscale: "zoomed",
    zoomtransform: "zoomed",
  },
  mounted() {
    this.call();
  },
  methods: {
    style(d) {
      return {
        fill: this.getFill(d),
      };
    },
    getTooltipMessage(d) {
      let msg = `<div><strong>${d.modulename} - ${d.varname}</strong><br>${d.date}: ${d.value}</div>`;
      const yMax = this.yscale.domain()[1];

      if (d.value > yMax) {
        msg = `&#10071; - Off scale<br>` + msg;
      }
      let table = this.line
        .map(v => {
          return `<tr class="${v === d ? "text-base" : "text-xs"}"><td class="w-1/2">${v.date}</td><td class="w-1/2">${
            v.value
          }</td></tr>`;
        })
        .join("");

      let tip = `<div>${msg}<table class="table-auto"><thead><tr><th colspan="2">Övriga registreringar</th></tr></thead><tbody>${table}</tbody></table></div>`;

      return tip;
    },
    getFill(d) {
      if (this.meta?.dot?.threshold) {
        const threshold = this.meta.dot.threshold;
        const tvalue = threshold.filter(t => {
          return inRange(d.value, t.range[0], t.range[1]) ?? t.color;
        });

        const color = tvalue.length ? tvalue[0].color : "black";

        return color;
      }

      let lineColor = d3.rgb(this.meta?.line?.color || "black").darker(0.5);
      const color = this.meta?.dot?.color || lineColor;

      this.$set(this.attrs, `${d.id}-${this.name}-fill`, color);
      return color;
    },
    getStroke(d) {
      return d3.rgb(this.getFill(d)).darker(1.5);
    },
    r(d) {
      const r = this.meta?.dot?.radius || 5;

      this.$set(this.attrs, `${d.id}-${this.name}-r`, r);
      return r;
    },
    cx(d) {
      if (this.xn !== null) {
        return this.xn(d.dateobj);
      }
      return this.xscale(d.dateobj);
    },
    cy(d) {
      const yMax = this.yscale.domain()[1];

      return d.value <= yMax ? this.yscale(d.value) : this.yscale(yMax);
    },
    zoomed() {
      if (!this.zoomtransform) return;

      this.xn = this.zoomtransform.rescaleX(this.xscale);
    },
    // hoverOn() {
    //   console.log(this);
    //   // d3.select(this).attr("fill", "red");
    // },
    call() {
      if (!this.$refs.dot) return;

      const vue = this;

      d3.selectAll(this.$refs.dot)
        .on("mouseover", function () {
          const selection = d3.select(this);
          const fill = selection.attr("fill");
          const r = selection.attr("r");
          selection
            .transition()
            .attr("fill", d3.rgb(fill).brighter(0.8))
            .attr("r", r * 1.5)
            .attr("stroke", "black");

          vue.$emit("chart-dot-hover", { name: vue.name });
        })
        .on("mouseout", function () {
          const selection = d3.select(this);
          const fill = vue.attrs[`${selection.attr("id")}-fill`];
          const r = vue.attrs[`${selection.attr("id")}-r`];
          selection.transition().attr("fill", fill).attr("r", r);

          vue.$emit("chart-dot-unhover", { name: vue.name });
        });
    },
  },
};
</script>

<style>
</style>
