<template>
  <g v-if="scale" ref="axis" class="axis" :class="axisplane" :transform="transform"></g>
</template>

<script>
import d3 from "@/components/graph/d3modules";

export default {
  name: "ChartAxis",
  props: {
    scale: Function,
    zoomtransform: Object,
    placement: String,
    height: Number,
    width: Number,
    margins: Object,
    ticks: Boolean,
    guide: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    axistype: {
      bottom: d3.axisBottom,
      top: d3.axisTop,
      left: d3.axisLeft,
      right: d3.axisRight,
    },
  }),
  beforeUpdate() {
    this.call();
  },
  mounted() {
    this.call();
  },
  watch: {
    zoomtransform: "zoomed",
  },
  computed: {
    innerHeight() {
      return this.height - this.margins.top - this.margins.bottom;
    },
    bottomBoundary() {
      const h = this.height - this.margins.bottom;
      const bottomBoundary = h > this.margins.top ? h : this.margins.top;
      return bottomBoundary;
    },
    transform() {
      return this.transformtype[this.placement];
    },
    transformtype() {
      return {
        bottom: `translate(0, ${this.bottomBoundary})`,
        top: `translate(0, ${this.margins.top})`,
        left: `translate(${this.margins.left}, 0)`,
        right: `translate(${this.width - this.margins.right}, 0)`,
      };
    },
    axisplane() {
      return {
        y: ["bottom", "top"].includes(this.placement),
        x: ["left", "right"].includes(this.placement),
      };
    },
  },
  methods: {
    call() {
      if (this.bottomBoundary > this.margins.top) {
        const axis = this.axistype[this.placement](this.scale);
        if (this.ticks) {
          axis.ticks(this.ticks);
        }
        if (this.guide && this.placement === "bottom") {
          axis.tickSizeInner(-this.innerHeight);
          axis.tickPadding(7);
        }
        if (this.placement === "left") {
          axis.tickSizeInner(-this.width + this.margins.right + this.margins.left);
          axis.tickPadding(7);
        }

        d3.select(this.$refs.axis).call(axis);
        this.formatYearTicks();
      }
    },
    zoomed() {
      if (["left", "right"].includes(this.placement)) return;

      const axis = this.axistype[this.placement](this.scale).scale(this.zoomtransform.rescaleX(this.scale));
      if (this.ticks) {
        axis.ticks(this.ticks);
      }
      if (this.guide && this.placement === "bottom") {
        axis.tickSize(-this.innerHeight);
        axis.tickPadding(7);
      }

      d3.select(this.$refs.axis).call(axis);
      this.formatYearTicks();
    },
    formatYearTicks() {
      if (this.axisplane.y) {
        // improve legibility of year on time axis
        d3.select(this.$refs.axis)
          .selectAll("text")
          .each(function () {
            if (+this.textContent) {
              this.classList.add("year");
            }
          });
      }
    },
  },
};
</script>

<style>
.axis path,
.axis line {
  stroke-width: 1;
  shape-rendering: crispEdges;
}

.tick path,
.tick line {
  stroke: gray;
  stroke-width: 1;
  shape-rendering: crispEdges;
  stroke-dasharray: 2 5;
  opacity: 0.35;
}
.year {
  fill: black;
  font-size: 1.25em;
}
</style>