<template>
  <svg ref="events" :viewBox="viewBox" :width="width" :height="height">
    <defs>
      <pattern id="diagonal-stripe-1" patternUnits="userSpaceOnUse" width="10" height="10">
        <image
          xlink:href="data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScxMCcgaGVpZ2h0PScxMCc+CiAgPHJlY3Qgd2lkdGg9JzEwJyBoZWlnaHQ9JzEwJyBmaWxsPSd3aGl0ZScvPgogIDxwYXRoIGQ9J00tMSwxIGwyLC0yCiAgICAgICAgICAgTTAsMTAgbDEwLC0xMAogICAgICAgICAgIE05LDExIGwyLC0yJyBzdHJva2U9J2JsYWNrJyBzdHJva2Utd2lkdGg9JzEnLz4KPC9zdmc+Cg=="
          x="0"
          y="0"
          width="10"
          height="10"
        ></image>
      </pattern>
    </defs>
    <clipPath id="eventclip">
      <rect
        :width="width - margins.left - margins.right"
        :height="innerHeight"
        :transform="`translate(${margins.left}, ${margins.top})`"
      ></rect>
    </clipPath>

    <g :transform="gtransform">
      <rect
        fill="url(#diagonal-stripe-1)"
        opacity="0.1"
        :width="width - margins.left - margins.right"
        :height="innerHeight"
        :transform="`translate(${margins.left}, ${margins.top})`"
      ></rect>

      <g v-for="(group, key) in groupedEvents" :key="key" clip-path="url(#eventclip)">
        <chart-event :x="x(group[0])" :y="height - margins.bottom" :group="group" />
      </g>

      <!-- <g v-for="(eventtype, eventkey) in events" :key="eventkey"> -->
      <!-- <g v-for="(event, key) in eventtype" :key="key" clip-path="url(#eventclip)"> -->
      <!-- <chart-event :x="x(event)" :y="y(event)" :eventtype="eventkey" :event="event" /> -->
      <!-- {{ groupedEvents }} -->
      <!-- <text :x="x(event) - 4" :y="y(event) - 15" fill="white">{{ eventkey }}</text>
          <rect
            :x="x(event)"
            :y="y(event)"
            :width="ewidth(event)"
            :height="eheight(event)"
            fill="steelblue"
            clip-path="url(#eventclip)"
          ></rect> -->
      <!-- </g> -->
      <!-- </g> -->
      <!--
      <chart-axis
        :scale="xscale"
        :width="width"
        :height="height"
        :margins="margins"
        :zoomtransform="zoomtransform"
        placement="bottom"
      /> -->
    </g>
  </svg>
</template>

<script>
// import ChartAxis from "./ChartAxis";
import d3 from "@/components/graph/d3modules";
import ChartEvent from "@/components/graph/ChartEvent";

export default {
  name: "EventGraph",
  props: ["height", "width", "xscale", "events", "zoom", "zoomtransform"],
  components: {
    ChartEvent,
    // ChartAxis,
  },
  watch: {
    height: {
      immediate: true,
      handler: "setyscale",
    },
    width: {
      immediate: true,
      handler: "setyscale",
    },
    zoomtransform: {
      immediate: false,
      handler: function () {
        this.updateZoom();
      },
    },
  },
  data() {
    return {
      margins: {
        top: 0,
        right: 50,
        bottom: 10,
        left: 50,
      },
      yscale: null,
      xn: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.call();
    });
  },
  computed: {
    groupedEvents() {
      const tmp = Object.entries(this.events)
        .map(d => {
          d[1].map(v => {
            v["type"] = d[0];
            return v;
          });
          return d[1];
        })
        .flat();
      const group = d3.groups(tmp, d => d.date);

      return group;
    },
    innerHeight() {
      const h = this.height - this.margins.top - this.margins.bottom;
      return h > 0 ? h : 1;
    },
    gtransform() {
      return `translate(0, 0)`;
    },
    h() {
      return this.height;
    },
    w() {
      return this.width;
    },
    viewBox() {
      return `0, 0, ${this.w || 0}, ${this.h || 0}`;
    },
    zoomedXscale() {
      return this.xn !== null ? this.xn : this.xscale;
    },
  },
  methods: {
    call() {
      d3.select(this.$refs.events).call(this.zoom);
    },
    updateZoom() {
      this.xn = this.zoomtransform.rescaleX(this.xscale.copy());

      d3.select(this.$refs.events).call(this.zoom);
    },
    setyscale() {
      if (!this.events) return;

      this.yscale = d3
        .scaleBand()
        .domain(["event"])
        .range([this.margins.top, this.height - this.margins.bottom]);
    },
    y() {
      return this.height - this.margins.bottom;
      // if (!this.yscale) return;

      // return this.yscale("event");
    },
    x(d) {
      return this.zoomedXscale(new Date(d));
    },
    ewidth() {
      return 1;
    },
    eheight() {
      return this.yscale.bandwidth();
    },
    showDetails(event) {
      console.log(event);
      console.log(
        Object.values(this.events)
          .filter(type =>
            type.filter(d => {
              console.log(d.date, event.date);
              return d.date === event.date;
            })
          )
          .flat()
      );
    },
  },
};
</script>
