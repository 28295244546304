<template>
  <div class="absolute right-5 top-0 mt-1">
    <div class="flex flex-col items-end text-sm">
      <div>
        <div class="inline-block mx-2 px-2 bg-yellow-50">
          <span class="mx-1">{{ $t("graph.zoomlevel.periodtext") }}</span>
          <span>{{ minDate }}</span>
          <span> - </span>
          <span>{{ maxDate }}</span>
        </div>
        <div class="inline-block space-x-1">
          <span>{{ $t("graph.zoomlevel.zoomtext") }}</span>
          <cm-button @click="$emit('zoomin', $event, { months: 6 })" size="small">{{
            $t("graph.zoomlevel.6m")
          }}</cm-button>
          <cm-button @click="$emit('zoomin', $event, { months: 12 })" size="small">{{
            $t("graph.zoomlevel.12m")
          }}</cm-button>
          <cm-button @click="$emit('zoomin', $event, { months: 24 })" size="small">{{
            $t("graph.zoomlevel.24m")
          }}</cm-button>
          <cm-button @click="$emit('zoomreset')" size="small">{{ $t("graph.zoomlevel.all") }}</cm-button>
        </div>
        <!-- <div class="inline-block">
        <svg :width="300" :height="50" ref="brusher">
          <g transform="translate(0, 0)">
            <chart-axis
              :scale="xscale"
              :width="300"
              :height="50"
              :margins="{ top: 0, right: 0, bottom: 20, left: 0 }"
              placement="bottom"
              :ticks="ticks"
            />
          </g>
        </svg>
      </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import d3 from "@/components/graph/d3modules";
import { dateFormat } from "@/utils/date";

// import ChartAxis from "./ChartAxis";

export default {
  name: "GraphControls",
  components: {
    // ChartAxis,
  },
  props: ["width", "xscale", "zoom", "zoomtransform"],
  watch: {
    zoomtransform: {
      immediate: true,
      handler: "zoomed",
    },
  },
  data: () => ({
    brush: null,
    minDateRaw: null, //this.xscale.domain()[0],
    maxDateRaw: null, //this.xscale.domain()[1],
  }),
  mounted() {
    // this.$nextTick(() => {
    this.call();
    // });
  },
  computed: {
    minDate() {
      return dateFormat(this.minDateRaw ?? this.xscale.domain()[0]);
    },
    maxDate() {
      return dateFormat(this.maxDateRaw ?? this.xscale.domain()[1]);
    },
    ticks() {
      return d3.timeYear.every(1);
    },
  },
  methods: {
    call() {
      this.brush = d3.brushX();
      d3.select(this.$refs.brusher).call(this.brush);

      this.brush.on("brush", this.brushed);
    },
    brushed(event) {
      console.log(event);
    },
    zoomed() {
      if (!this.zoomtransform) return;

      const axis = d3.axisBottom(this.xscale).scale(this.zoomtransform.rescaleX(this.xscale));
      this.minDateRaw = axis.scale().domain()[0];
      this.maxDateRaw = axis.scale().domain()[1];
    },
  },
};
</script>

<style>
</style>