<template>
  <svg ref="therapies" :viewBox="viewBox" :width="width" :height="height">
    <clipPath id="therapyclip">
      <rect
        fill="red"
        :width="width - margins.left - margins.right"
        :height="innerHeight"
        :transform="`translate(${margins.left}, ${margins.top})`"
      ></rect>
    </clipPath>

    <g :transform="gtransform">
      <chart-legend
        :legends="legends()"
        :margins="margins"
        :showhideall="true"
        @inactivate="inactivate"
        refname="legend-therapies"
      />

      <g v-for="(therapy, key) in therapies" :key="key" v-show="isActive(therapy.id)">
        <rect
          :x="x(therapy)"
          :y="y(therapy)"
          :width="twidth(therapy)"
          :height="theight(therapy)"
          :fill="tcolor(therapy)"
          clip-path="url(#therapyclip)"
        ></rect>
      </g>

      <chart-axis
        :scale="xscale"
        :width="width"
        :height="height"
        :margins="margins"
        :zoomtransform="zoomtransform"
        placement="bottom"
      />
    </g>
  </svg>
</template>

<script>
import ChartAxis from "./ChartAxis";
import ChartLegend from "./ChartLegend";
import d3 from "@/components/graph/d3modules";

export default {
  name: "TherapyGraph",
  props: ["height", "width", "xscale", "therapies", "zoom", "zoomtransform"],
  components: {
    ChartAxis,
    ChartLegend,
  },
  watch: {
    height: {
      immediate: true,
      handler: "setyscale",
    },
    width: {
      immediate: true,
      handler: "setyscale",
    },
    zoomtransform: {
      immediate: false,
      handler: function () {
        this.updateZoom();
      },
    },
  },
  data() {
    return {
      margins: {
        top: 25,
        right: 50,
        bottom: 20,
        left: 40,
      },
      inactive: [],
      yscale: null,
      xn: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.call();
    });
  },
  computed: {
    innerHeight() {
      const h = this.height - this.margins.top - this.margins.bottom;
      return h > 0 ? h : 1;
    },
    gtransform() {
      return `translate(0, 0)`;
    },
    h() {
      return this.height;
    },
    w() {
      return this.width;
    },
    viewBox() {
      return `0, 0, ${this.w || 0}, ${this.h || 0}`;
    },
    zoomedXscale() {
      return this.xn !== null ? this.xn : this.xscale;
    },
  },
  methods: {
    call() {
      d3.select(this.$refs.therapies).call(this.zoom);
    },
    updateZoom() {
      this.xn = this.zoomtransform.rescaleX(this.xscale.copy());

      d3.select(this.$refs.therapies).call(this.zoom);
    },
    setyscale() {
      if (!this.therapies) return;

      this.yscale = d3
        .scaleBand()
        .domain(
          this.therapies
            .sort((a, b) => {
              return d3.descending(a.start_date, b.start_date);
            })
            .map(d => {
              return d.drug.name;
            })
        )
        .range([this.margins.top, this.height - this.margins.bottom]);
    },
    y(d) {
      if (!this.yscale) return;
      return this.yscale(d.drug.name);
    },
    x(d) {
      return this.zoomedXscale(d.start_date);
    },
    twidth(d) {
      return this.zoomedXscale(d.end_date) - this.zoomedXscale(d.start_date);
    },
    theight() {
      if (!this.yscale) return;

      return d3.min([this.yscale.bandwidth(), 10]);
    },
    tcolor(d) {
      return (
        "#" + d.drug?.attributes.filter(d => d.attribute_type === "color")[0].attribute_value.replace("#", "") ||
        "steelblue"
      );
    },
    legends() {
      if (!this.therapies) return;

      let legends = [];

      Object.values(this.therapies).forEach(d => {
        const color =
          "#" + d.drug?.attributes.filter(d => d.attribute_type === "color")[0].attribute_value.replace("#", "") ||
          "steelblue";
        legends.push({
          label: d.drug.name,
          color: color,
          id: d.id,
        });
      });

      return legends;
    },
    inactivate(d) {
      this.inactive = d;
    },
    isActive(id) {
      if (!this.therapies) return;

      const inactive = this.inactive.find(d => d === id);

      return inactive ? false : true;
    },
  },
};
</script>

<style>
</style>